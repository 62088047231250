
#login {
  height: 100%;
  width: 100%;
  position:absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login.card {
  background: #EEE;
  box-shadow: 0px 0px 10px 5px #DDD;
}
.login.card .errormsg {
  border-radius: .25rem;
  box-shadow: 0px 0px 10px 1px #e42c3e;
}
.login.card .center .button {
  display:block;
  margin: 5px 0px;
}
.center .retina-logo img {
  height:90px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.copyright {
  margin-top:10px;
}

